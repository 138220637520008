define('ui/admin-tab/auth/github/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model() {
      return this.get('authStore').find('config', null, { forceReload: true }).then(function (collection) {
        return collection;
      });
    },

    setupController: function setupController(controller, model) {
      controller.setProperties({
        model: model,
        confirmDisable: false,
        testing: false,
        organizations: this.get('session.orgs') || [],
        errors: null,
        isEnterprise: model.get('githubConfig.hostname') ? true : false,
        secure: model.get('githubConfig.schema') === 'https://'
      });

      controller.set('saved', true);
    }
  });
});