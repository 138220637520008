define('ui/components/webhook/service-upgrade-config/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: '',
    payloadFormatChoices: null,

    init: function init() {
      this._super.apply(this, arguments);
      this.initPayloadFormatChoices();
    },

    initPayloadFormatChoices: function initPayloadFormatChoices() {
      var serviceUpgradeSchema = this.get('webhookStore').getById('schema', 'serviceupgrade');
      var payloadFormatSchema = serviceUpgradeSchema.resourceFields.payloadFormat;
      var choices = payloadFormatSchema.options.map(function (option) {
        return { label: 'newReceiver.payloadFormat.' + option, value: option };
      });
      this.set('payloadFormatChoices', choices);
    },

    actions: {
      optionsChanged: function optionsChanged(opt) {
        this.get('model').setProperties(opt);
      }
    }
  });
});